/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap'; // eslint-disable-next-line
import { copiriAxiosPost } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader'; // eslint-disable-next-line
import img_1 from '../../../assets/img/lodges/img_1.png'; // eslint-disable-next-line
import img_2 from '../../../assets/img/lodges/img_2.png'; // eslint-disable-next-line
import img_3 from '../../../assets/img/lodges/img_3.png'; // eslint-disable-next-line
import img_4 from '../../../assets/img/lodges/img_4.png'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import LodgeManageImagesModal from './LodgeManageImagesModal'; // eslint-disable-next-line
import org_image from '../../../assets/img/logos/no_gl_seal_light.png';
const LodgeModalImageSection = props => {
	// eslint-disable-next-line
	const { lodgeData, pageLanguageContent, updateLodgeModal, lodgeImages } = props;
	const [showHideImageModal, setShowHideImageModal] = useState(false);
	const [lodgeImagesData,setLodgeImagesData] =useState([]);
	useEffect(() => {
		setLodgeImagesData(lodgeImages)
	}, [lodgeImages]);

	/* function to show lodge images modal */
	const openManageImagesModal = () => {
		setShowHideImageModal(true);
	};
	/* function to hide lodge images modal */
	const closeManageImagesModal = () => {
		setShowHideImageModal(false);
	};

	/* callback function to update the images */
	function callBackImagesData(imagesData) {
		setLodgeImagesData(imagesData);
	}
	return (
		<>
			<Row className='lodges-bottom-footer'>
				<ul>
					{lodgeImagesData &&
						lodgeImagesData.length > 0 &&
						lodgeImagesData.map((item, i) => (
							<span key={i}>
								{i < 5 && (
									<li onClick={() => openManageImagesModal()}>
										<img src={item.Seal}  onError={(e) =>((e.target.src =org_image))}  alt={''} />
									</li>
								)}
							</span>
						))}

					{lodgeImagesData && lodgeImagesData.length >= 5 && (
						<li>
							<div className='btn-img-lodge'>
								<Button className='btn-lodge-vl' color='none' onClick={() => openManageImagesModal()}>
									{pageLanguageContent['CLD129']?pageLanguageContent['CLD129']:''}
								</Button>
							</div>
						</li>
					)}
					{lodgeImagesData && lodgeImagesData.length < 5 && (
						<li className='ml-auto'>
							<div className=''>
								<Badge color={'soft-info'} className='mr-1'>
									<i className='far fa-edit' onClick={() => openManageImagesModal()}></i>
								</Badge>
							</div>
						</li>
					)}
				</ul>
			</Row>
			<LodgeManageImagesModal
				showHide={showHideImageModal}
				closeModal={closeManageImagesModal}
				lodgeImages={lodgeImagesData}
				pageLanguageContent={pageLanguageContent}
				lodgeData={lodgeData}
				updateLodgeModal={updateLodgeModal}
				callBackImagesData={callBackImagesData}
			/>
		</>
	);
};
export default LodgeModalImageSection;
