/* eslint react/no-multi-comp: 0, react/prop-types: 0 */ // eslint-disable-next-line
import React, { useState, useEffect } from 'react';

// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; // eslint-disable-next-line
import FormGroupInput from '../../common/FormGroupInput'; // eslint-disable-next-line
import { copiriAxiosPost, copiriAxiosGet,copiriPortalRequest,getTotalAmount } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
import default_org_img from '../../../assets/img/logos/no_gl_seal_light.png';// eslint-disable-next-line
import {genPageData} from '../../langconfig/langconfig'; // eslint-disable-next-line
import './payment_transacton.css';
const CompleteTransactionData = props => {
	const { transactionData,pageLanguageContent } = props; //get prop data
	return (
		<>
			<Row>
				<Col lg={12} md={12} sm={12} style={{textAlign:'center'}} className='p-1 mt-1'>
					<h4>
						{
							transactionData.org_seal?
								<img className='rounded-circle avatar-xl' width="45" src={transactionData.org_seal} alt='' />
							:
								<img className='rounded-circle avatar-xl' width="45" src={default_org_img} alt='' />
						}
						<span style={{marginLeft:'10px'}}>{transactionData.org_name?transactionData.org_name:''}</span>
					</h4>
					<h6><span style={{fontWeight:'600',fontSize:'24px'}}>$ {getTotalAmount(transactionData.amount)}</span></h6>
					<p style={{fontWeight:'400',fontSize:'16px',marginTop:'-5px'}}>{transactionData.status?transactionData.status:''}</p>
				</Col>	
				<Col lg={12} style={{marginTop:'-15px'}}>
					<hr className="bg-grey"/>
				</Col>
			</Row>
			<Row>
				<Col xs='6'>
					<span>{new Date(transactionData.completed * 1000).toLocaleString()}</span>
				</Col>

				<Col xs='6' className='text-right'>
					<span className="user_profile_text">{pageLanguageContent['PMT024'] ? pageLanguageContent['PMT024'] : ''}: {transactionData?.public_id}</span>
				</Col>
				<Col lg={12}>
					<hr className="bg-grey"/>
				</Col>
			</Row>
			<Row>
				<Col xs='6'>
					<span className="heading">{pageLanguageContent['PMT025'] ? pageLanguageContent['PMT025'] : ''}:</span>
				</Col>

				<Col xs='6' className='text-right'>
					<span className="user_profile_text">{transactionData?.paid_by}</span>
				</Col>
				<Col lg={12}>
					<hr className="bg-grey"/>
				</Col>
			</Row>
			<Row className='transaction-detail-data'>
				<Col xs={12}>
					<span className="heading">{pageLanguageContent['PMT026'] ? pageLanguageContent['PMT026'] : ''}:</span>
				</Col>
			</Row>
			<Row>
				<Col xs='8'>
					<span>{pageLanguageContent['PMT027'] ? pageLanguageContent['PMT027'] : ''}:</span>
				</Col>
				<Col xs='4' className='text-right'>
					<span className='amount'>{transactionData?.method}</span>
				</Col>
			</Row>
			<Row>
				<Col xs='8'>
					<span>{pageLanguageContent['PMT028'] ? pageLanguageContent['PMT028'] : ''}:</span>
				</Col>
				<Col xs='4' className='text-right'>
					<span className='amount'>{transactionData?.account}</span>
				</Col>
				<Col lg={12}>
					<hr className="bg-grey"/>
				</Col>
				<Col xs={12}>
					<span className="heading">{pageLanguageContent['PMT029'] ? pageLanguageContent['PMT029'] : ''}:</span>
				</Col>
			</Row>
			{transactionData &&
				transactionData?.entries?.length > 0 &&
				transactionData.entries.map((item, i) => (
					<Row key={i}>
						<Col xs='8'>
							<p className='mb-0'>{item.description}</p>
						</Col>
						<Col xs='4' className='text-right'>
							<span className='amount'>$ {getTotalAmount(item.amount)}</span>
						</Col>
					</Row>
				))
			}
			<Row className="mb-2">
				<Col lg={12}>
					<hr className="bg-grey"/>
				</Col>
				<Col xs={6}>
					<span className="heading">{pageLanguageContent['PMT017'] ? pageLanguageContent['PMT017'] : ''}:</span>
				</Col>
				<Col xs={6} className='text-right'>
					<span className="heading">$ {transactionData?.amount >0 ? getTotalAmount(transactionData?.amount) :'0.00'}</span>
				</Col>
			</Row>
		</>
	);
};

export default CompleteTransactionData;
